<template>
  <div>
    <div id="app">
      <div v-if="this.isSidenavVisible" class="full-screen-overlay" @click="closeSidenav"></div>
      <app-skiplinks></app-skiplinks>
      <app-header></app-header>
      <app-Visitors></app-Visitors>
      <app-sidenav v-if="this.isSidenavVisible" />
      <main id="content" role="main">
        <router-view />
      </main>
      <app-footer></app-footer>
    </div>
  </div>
</template>

<script>
import HeaderDsfr from "./components/Header.vue";
import FooterDsfr from "./components/Footer.vue";
import SkipLinksDsfr from "./components/SkipLinks.vue";
import Sidenav from "./components/Sidenav.vue";
import { mapGetters, mapMutations } from "vuex";
import Visitors from "./components/Visitors.vue";

export default {
  components: {
    "app-sidenav": Sidenav,
    "app-header": HeaderDsfr,
    "app-Visitors": Visitors,
    "app-footer": FooterDsfr,
    "app-skiplinks": SkipLinksDsfr,
  },
  computed: {
    ...mapGetters(['isSidenavVisible']),
  },
  watch: {
    $route(to) {
      document.title =
        to.meta.title ||
        "L’Observatoire des données des sites Internet de l’État";
    },
  },
  methods: {
    ...mapMutations(['SET_IS_SIDENAV_VISIBLE']),
    closeSidenav() {
      this.SET_IS_SIDENAV_VISIBLE(false);
    },
  }
};
</script>
<style>
@import 'styles/global.scss';
</style>
