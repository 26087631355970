const analytics_config_file = {
    // verbose : true,
    analytics: {
        cmp: {
            id: 'tarteaucitron'
        },
        domain: process.env.VUE_APP_TRACKING,
        collection: 'manual',//'hash',//full produces double collector
        isActionEnabled: true,
        isDebugging: false,
        page: {
            // path: '', // Chemin pour le suivi de la page           
            // referrer: '', // Référent pour les pages virtuelles (pas pour les pages réelles, eulerian collecte automatiquement document.referrer)
            // id: '', // ID de page unique (chaîne de caractères)
            // title: '', // Titre de la page pour les pages virtuelles
            // name: '', // Équivalent au titre si non défini
            // author: '', // Nom de l'auteur de la page
            date: new Date().toISOString(),
            labels: ['audience-communication', '', '', '', ''],
            // tags: [], // Pas de limite de getTags
            template: 'audience-communication', // Template de page
            group: 'audience-communication', // Groupe de page. Si non défini, utilisation de la valeur du template
            segment: 'audience-communication', // Segment du site. Si non défini, utilisation de la valeur du template
            // subtemplate: '', // Sous-template de page
            // theme: '', // Thème de page
            // subtheme: '', // Sous-thème de page
            // related: '', // ID de page liée
            // depth: 1, // Profondeur de la page
            // isError: false, // Est-ce une page d'erreur (404, 500, 503...)
            // current: 0, // En cas de pagination, numéro de page actuelle
            // total: 0, // En cas de pagination, nombre total de pages 
            // filters: [] // Tableau des filtres appliqués sur la page (chaînes de caractères)
        },
        user: {
            connect: {
                uid: '', // ID de l'utilisateur - requis lorsque connecté
                email: '', // Email de l'utilisateur encodé - requis lorsque connecté
                isNew: true, // L'utilisateur vient de s'inscrire
            },
            profile: 'visitor', // Profil de l'utilisateur
            language: 'fr',
            type: 'pro'
        },
        site: {
            entity: 'SPM || SIG',
            environment: process.env.VUE_APP_ENV,
            language: 'fr', // Langue du site web 
            target: 'information', // Cible du site
            type: 'standard', // Type de site
            // region: '', // Région du site - format correct, remplacez XX par le code de la région
            // department: '', // Département du site - format correct, remplacez YY par le code du département
            version: '', // Version du site
        },
        search: {
            engine: '',
            results: '',
            terms: '',
            category: '',
            theme: '',
            type: '',
            method: ''
        },
        opt: {
            enable: true
        }
    }
};
export default analytics_config_file;

