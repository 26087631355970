<template>
  <div class="fr-card fr-card--no-icon fr-card--shadow fr-mt-5w">
    <div class="fr-p-2w">
      <h3 class="cardTitle" :aria-labelledby="'map' + '1'">Provenance géographique</h3>
      <p class="fr-text--sm fr-text--regular fr-mb-1w"> {{ descriptionCartes }} </p>
    </div>

    <div class="fr-grid-row--right fr-px-1w cardReference">
      <segmented-controls v-on:chart-selected="handleChartSelected" :idcontrol="'map' + '1'"></segmented-controls>
    </div>

    <div class="fr-card--no-border fr-card--no-icon fr-mt-1w">
      <div v-if="queryMapSuccess" style="background-color: white">
        <!-- Onglets -->
        <div class="fr-tabs">
          <ul class="fr-tabs__list" role="tablist" aria-label="Onglets mailles">
            <li role="presentation">
              <button id="tabpanel-reg" class="fr-tabs__tab fr-icon-checkbox-line fr-tabs__tab--icon-left" role="tab"
                      aria-selected="true" aria-controls="tabpanel-reg-panel">
                Région
              </button>
            </li>
            <li role="presentation">
              <button id="tabpanel-dep" class="fr-tabs__tab fr-icon-checkbox-line fr-tabs__tab--icon-left" role="tab"
                      aria-selected="false" aria-controls="tabpanel-dep-panel">
                Département
              </button>
            </li>
            <li role="presentation">
              <button id="tabpanel-inter" class="fr-tabs__tab fr-icon-checkbox-line fr-tabs__tab--icon-left" role="tab"
                      aria-selected="false" aria-controls="tabpanel-inter-panel">
                International
              </button>
            </li>
          </ul>
          <!-- Contenu des onglets -->
          <div id="tabpanel-reg-panel" class="fr-tabs__panel fr-tabs__panel--selected" role="tabpanel"
               aria-labelledby="tabpanel-reg" tabindex="0">
            <MapBox :data="dataReg" geolevel="reg" :valueNat="valueNat" :noMapped="noMapped" :updateDate="updateDateMap"
                    v-if="displayChart">
            </MapBox>
            <table-component :captionTitle="'Provenance géographique par région'" v-else :table="tableReg"
                             class="fr-mx-n2w fr-mt-n2w"></table-component>
          </div>

          <div id="tabpanel-dep-panel" class="fr-tabs__panel" role="tabpanel" aria-labelledby="tabpanel-dep"
               tabindex="0">
            <MapBox :data="dataDep" geolevel="dep" :valueNat="valueNat" :noMapped="noMapped" :updateDate="updateDateMap"
                    v-if="displayChart">
            </MapBox>
            <table-component :captionTitle="'Provenance géographique par département'" v-else :table="tableDept"
                             class="fr-mx-n2w fr-mt-n2w "></table-component>
          </div>

          <div id="tabpanel-inter-panel" class="fr-tabs__panel" role="tabpanel" aria-labelledby="tabpanel-inter"
               tabindex="0">
            <!-- Contenu -->
            <MapWorldChart :dataCountry="dataCountry" :updateDate="updateDateMap" :totalCountry="totalCountry"
                           :noMapped="noMappedCountry" v-if="displayChart"></MapWorldChart>
            <table-component :captionTitle="'Provenance géographique à l’international'" v-else :table="tableCountry"
                             class="fr-mx-n2w fr-mt-n2w "></table-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MapBox from "./MapBox.vue";
import MapWorldChart from "../charts/MapWorldChart.vue";
import SegmentedControls from "../SegmentedControls.vue";
import TableComponent from "../TableComponent.vue";

export default {
  name: "MapsBox",
  components: {
    MapBox,
    MapWorldChart,
    SegmentedControls,
    TableComponent,
  },
  data() {
    return {
      //maps
      queryMapSuccess: false,
      dataDep: "",
      dataReg: "",
      dataCountry: Object,
      totalCountry: 0,
      noMappedCountry: 0,
      valueNat: 0,
      noMapped: 0,
      descriptionCartes: "",
      updateDateMap: "",
      tableReg: {},
      tableDept: {},
      tableCountry: {},
      displayChart: false,
    };
  },
  props: {
    query: Object,
  },
  methods: {
    maps(params) {
      const urlApi =
          process.env.VUE_APP_API_URL + "/requests/visits_regions_departements";
      const params_map = JSON.parse(JSON.stringify(params));
      params_map.table_name = "ft_visite";
      axios.post(urlApi, params_map).then((response) => {
        if (response.data.status) {
          this.queryMapSuccess = true;
          const data = response.data.data;
          this.valueNat = data.total_france;
          this.noMapped = data.no_mapped_france;

          this.dataDep = JSON.stringify(
              data.departement.series[0].serie_values.nb_visite
          );
          this.updateDateMap = data.update_date;
          this.tableDept = data.departement.series[0].table;

          this.dataReg = JSON.stringify(
              data.region.series[0].serie_values.nb_visite
          );
          this.tableReg = data.region.series[0].table;

          this.dataCountry = data.country.series[0].serie_values.nb_visite;
          this.tableCountry = data.country.series[0].table;

          this.totalCountry = data.total;
          this.noMappedCountry = data.no_mapped_country;
          this.descriptionCartes = data.description;
          this.table = this.tableReg;
        } else {
          this.queryMapSuccess = false;
        }
      });
    },
    handleChartSelected(type) {
      this.displayChart = type === "graphique";
    },
  },
  watch: {
    query: function () {
      this.maps(this.query);
    },
  },
  mounted() {
    this.maps(this.query);
  },
};
</script>
<style scoped lang="scss">
@import '../../styles/components/boxes/mapsBox.scss';
</style>
