<template>
  <div class="fr-card fr-card--no-icon fr-card--shadow fr-mt-5w">
    <div class="fr-p-2w">
      <h3 class="cardTitle" :aria-labelledby="dataObj.accordion.id + '1'">{{ dataObj.titleBox.title }}</h3>
      <p class="fr-text--sm fr-text--regular fr-mb-1w"> {{ dataObj.accordion.description }} </p>
    </div>

    <div class="cardReference">
      <segmented-controls v-on:chart-selected="handleChartSelected"
        :idcontrol="dataObj.accordion.id + '1'"></segmented-controls>
    </div>

    <div :is="dataObj.graph.component" v-bind="{ serieObj: dataObj.graph.props }" v-if="displayChart"></div>
    <table-component v-else :captionTitle="dataObj.titleBox.title" :table="dataObj.graph.table"></table-component>
  </div>
</template>

<script>
import SegmentedControls from "../SegmentedControls.vue";
import TableComponent from "../TableComponent.vue";

export default {
  name: "GraphBoxGeneric",
  components: {
    SegmentedControls,
    TableComponent,
  },
  props: {
    dataObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      displayChart: false,
    };
  },
  methods: {
    handleChartSelected(type) {
      this.displayChart = type === "graphique";
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../styles/components/boxes/graphBoxGeneric.scss';
</style>
