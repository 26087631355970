<script>
import mixin from './base'

export default {
    mixins: [mixin],
    props: {
        props: Object
    }
}
</script>

<template>
    <svg version="1.1" :viewBox="props.viewBox" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true">
        <g fill-rule="nonzero" stroke="#FFFFFF" stroke-width="0.2%">
            <path fill="#6b6b6b" class="FR-acad-09" :style="{ display: props.displayDep['FR-acad-09'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M529.125,126.425l-9-10.3l-2-6.6l-3.3-2.3l-12.4-2.8l-5,3.9l-3.1,0.1l-5.8-2.6l-0.2-0.1l-0.2-0.1l-3.6-3.1
                h-7.7l-1.4,3l-6,0.9h-0.3l-3.8-8.1h-0.1l-2,1.4l-2.3,3l-6.4-2.3l-2.1,2.2l0.9,9.8l-3.6,5.7l1.5,2.9l-4.6,4.1l2.4,10.1l-1.1,4.7
                l2.9,1.4l-0.6,4.4l-3.5,1.2l-1.3,6.6l3.6,5.2l0.6,4.5l3.8,5.9l18.9,11.6l-2.6,2.7l14.3,10.7l-2,9.6l6.2,5.1l0.3,3.6l3-1.2l1.4,2.8
                v0.1l9.1-6.4l7.9,4.7l6.4-1.8l5.5,4.1l5.7-2.5l9.4,7.4l1.1-0.7l3.1-2.7v-6.9l5.3-8.8l4.7-15.2l1.2-1l-5.8-2.6l1.5-11.2l-2.2-1.5
                l3.9,0.4l2.5-2l3.4-15l-4.9-3.7l-3.2-0.5l-1.6,2.8l-2.6-1.8l1.9-2.8l-6.1-2.8l5.4-12.1l1,3.1l10.1,5.3l10.1-0.4l1.6-2.9l1.5-4.7
                h-0.2l-9.3-8.5l-7.2,4.6l-7.7-1.3l-2.9,1.4l-2.5-4.4l-3.4-1.6l-3.2,1.8l-0.3,3.3L529.125,126.425z" />
            <path fill="#6b6b6b" class="FR-acad-11" :style="{ display: props.displayDep['FR-acad-11'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M539.125,143.625l6.1,2.8l-1.9,2.8l2.6,1.8l1.6-2.8l3.2,0.5l4.9,3.7l-3.4,15l-2.5,2l-3.9-0.4l2.2,1.5
                l-1.5,11.2l5.8,2.6l-1.2,1l-4.7,15.2l-5.3,8.8v6.9l-3.1,2.7l8.5,5.6l0.5,10.2l3.1-0.2l2.5,5.7l-0.5,0.1l3.3,0.7l-0.8,3.4h8.5
                l3-1.1l0.5-3.4l3.3-0.2l0.2-3.5l2.5-2.2l-3.2-8.7l3-17.3l-2.1-11.6l6.6-15.7l1.6-17.6l11.8-15.3l4.5-12l-9.7-3.4l-16.3-0.5l0,0
                l-1.5,4.7l-1.6,2.9l-10.1,0.4l-10.1-5.3l-1-3.1L539.125,143.625z" />
            <path fill="#6b6b6b" class="FR-acad-15" :style="{ display: props.displayDep['FR-acad-15'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M407.925,55.425l-4.6-5.5l-6.8,1.2l-6.1-1.5l-2.5,2.3l-2.4-10.2l-6.1-1.5l-1.3-3l-3.2,2.4l-3.6-1.2
                l-3.4-9.5l0.5-3.4l-4.4-5l-6.5,0.6l-6.3,4.4l-3.3-1.5l-3.2-5.9h-3.7l-1.5-3l1.2-6.4l-2.9-6.8l-3.1-1.8l-18.3,4.5l-15.3,4.8
                l-6.5,5.5l-0.1,20.3l3.3,4l-3.1-2l-0.8,6.6l0.5,4.3l2.6,1.8l13.7,1.2l1.1,3.4l8,6.5l13.4-2.9l-2.9,6.5l1.9,3.5l2.8-3.5l9.4,3.9
                l1-3.1l3.1,5.2l2.7-1.9l0.9,3.5l9.6-2l3.7,3l14.2-0.7l2.9-2.5l2.6,1.9l5.8-2l17.6,4.7v0.1l3.6-6.8l-3.4-6l2.3-7.2L407.925,55.425z
                " />
            <path fill="#6b6b6b" class="FR-acad-14" :style="{ display: props.displayDep['FR-acad-14'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M408.425,75.025l0.1-0.4l-17.6-4.7l-5.8,2l-2.6-1.9l-2.9,2.5l-14.2,0.7l-3.7-3l-9.6,2l-0.9-3.5l-2.7,1.9
                l-3.1-5.2l-1,3.1l-9.4-3.9l-2.8,3.5l-1.9-3.5l2.9-6.5l-13.4,2.9l-8-6.5l-1.1-3.4l-13.7-1.2l-4.2,1.6l-0.4,3.4l3.4,6.2l-3.1-1.7
                l-7.3,10l0,0l14.1,12l3.9,9.5l-3.5,5.2l1.5,9.9l-1.5,9l4,9.4l-4.5,2.9l1.8,3.6l6.3,1.2l7.1-2l10.3,2.5l2.5-1.9l12.2,7.2l7.7,1.1
                l2.3-2.4l2.8,1.8l9.1-3.3l3,0.8l2,2.8l-0.8,3.2l4.4,4.7l10.4,6.8l8.6-11.5l-2.8-1.5l1-3l-1.8-2.8l6.1-2.5l-3.6-9l1.4-3l8.9-4.4
                l6,2l1.1-3.2l-0.3-16l3.1-0.3l5.6-8.1l-1.5-3.5l1.5-3.9l-0.9-6.4l-0.4,0.1L408.425,75.025z" />
            <path fill="#6b6b6b" class="FR-acad-10" :style="{ display: props.displayDep['FR-acad-10'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M412.925,76.325l0.9,6.4l-1.5,3.9l1.5,3.5l-5.6,8.1l-3.1,0.3l0.3,16l-1.1,3.2l-6-2l-8.9,4.4l-1.4,3l3.6,9
                l-6.1,2.5l1.8,2.8l-1,3l2.8,1.5l-8.6,11.5l-0.5,2.2l-2.8,2.5l3.9,9.3l2.9,1.9l-3.4,5.6l-3.3,0.8l0.5,9.6l4.1,1.6l5,6.3l2.8,9.3
                l2.2-2.5l4.1,4.7l5.6,12.3l14.1-1.8l3.3,1.5l2.5-2.2l6.9-1l5.3-4.4l4.3,0.6v-0.1l3.9,0.8l0.6,3.1l3,1.1l-0.4,3.2l3.3,0.4l1.9,2.4
                l1.2,3.5l-3.1,2.8l2.5,5.3l10.4,2.3l3.4,1.7v3.2l5.4-2.1l1.3-2.8l7.4-4.4l2.5,2l3.4-1.1l0.3-9.4l2.4-2.7l3.2,0.4l2.5-3.6l-0.2-1.6
                v-0.1l-1.4-2.8l-3,1.2l-0.3-3.6l-6.2-5.1l2-9.6l-14.3-10.7l2.6-2.7l-18.9-11.6l-3.8-5.9l-0.6-4.5l-3.6-5.2l1.3-6.6l3.5-1.2
                l0.6-4.4l-2.9-1.4l1.1-4.7l-2.4-10.1l4.6-4.1l-1.5-2.9l3.6-5.7l-0.9-9.8l2.1-2.2l6.4,2.3l2.3-3l2-1.4l-3.6,0.3l0.1-3.6l-6.8-1.6
                l-7.6-6.7l-6.6,0.2l1-7.3l-3.2-6.4v-4.7l3-5.6l-3.1-1.8l-4.5,4.8l-2,7.2l-9.8,4.4l-6-2L412.925,76.325z" />
            <path fill="#6b6b6b" class="FR-acad-16" :style="{ display: props.displayDep['FR-acad-16'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M347.325,139.025l-7.7-1.1l0.3,2.3l-2,2.6l-0.1,0.1c-0.7,0.5-1.5,1-2.3,1.6c-1,0.7-2,1.4-3,2.1l-2.9-1.2
                l-3.7,1.5l1.5,2.2l-0.1,1.2l0.3,3.4l2.3-1.6v-2.2h5.7l5.4,2.5l5.1,5v3.6l-1.8,4.9h0.2l5.4,2.3l3.9,0.3v4.7l-1.2,0.7l-2.4-3.4h-4.9
                l-0.5,1.5h-5.2v0.8h-1.8l-2.8,12.4l-3.9,5.3l0.8,3.5l4.7,4.8l-0.6,3l-3,1.7l10.3,0.8l12.6-3l4.3-6l0.3-6.6l17.3-3.4l-0.5-9.6
                l3.3-0.8l3.4-5.6l-2.9-1.9l-3.9-9.3l2.8-2.5l0.5-2.2l-10.4-6.8l-4.4-4.7l0.8-3.2l-2-2.8l-3-0.8l-9.1,3.3l-2.8-1.8L347.325,139.025
                z" />
            <path fill="#6b6b6b" class="FR-acad-18" :style="{ display: props.displayDep['FR-acad-18'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M339.925,140.225l-0.3-2.3l-12.2-7.2l-2.5,1.9l-10.3-2.5l-7.1,2l-6.3-1.2l-1.8-3.6l-4.5,10.6h-4.2
                l-2.6,2.8l2,6.6l4.1,7.4l0.5,12.2l7,6.1l1.4,6.3l2.4,2.4l3.5-0.7l3.2,11.9l0,0l7.8-0.9l2.2-3.1l1.6,2.9l3.1-1.9l4.2,0.6l3.9-5.3
                l2.8-12.4h-6.4l-4.3-4.3l-5.2-2v-1.7h-4.5l-2.1-1.7l-0.1-0.7c-0.1-0.4-0.1-0.7,0-1.1l-0.1-0.3l0.1-0.1l3-3.7h4.4l1.6-1
                c0.3-0.3,0.5-0.4,0.8-0.6l2.6-1.9l-0.3-3.4l0.1-1.2l-1.5-2.2l3.7-1.5l2.9,1.2c1-0.7,2-1.4,3-2.1c0.8-0.6,1.6-1.1,2.3-1.6l0.1-0.1
                L339.925,140.225z" />
            <path fill="#6b6b6b" class="FR-acad-17" :style="{ display: props.displayDep['FR-acad-17'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M335.625,149.925h-5.7v2.2l-2.3,1.6l-2.6,1.9c-0.3,0.2-0.5,0.3-0.8,0.6l-1.6,1h-4.4l-3,3.7l-0.1,0.1
                l0.1,0.3c-0.1,0.4-0.1,0.7,0,1.1l0.1,0.7l2.1,1.7h4.5v1.7l5.2,2l4.3,4.3h6.4h1.8v-0.8h5.2l0.5-1.5h4.9l2.4,3.4l1.2-0.7v-4.7
                l-3.9-0.3l-5.4-2.3h-0.2l1.8-4.9v-3.6l-5.1-5L335.625,149.925z" />
            <path fill="#6b6b6b" class="FR-acad-05" :style="{ display: props.displayDep['FR-acad-05'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M448.425,218.225l-3.3-0.4l0.4-3.2l-3-1.1l-0.6-3.1l-3.9-0.8v0.1l-4.3-0.6l-5.3,4.4l-6.9,1l-2.5,2.2
                l-3.3-1.5l-14.1,1.8l-5.6-12.3l-4.1-4.7l-2.2,2.5l-2.8-9.3l-5-6.3l-4.1-1.6l-17.3,3.4l-0.3,6.6l-4.3,6l7.5,8.4l0.5,6.5l-5.2,5.5
                l1.2,3.3l-1.7,2.7l-6,3.7l3.4,3.2l2.4,7.8l-3.1,0.7l-1.7,2.7l2.2,7.3l-1.9,6.5l3.1,2.4l3.5,8.5l0.3,6.6l2.5,2.2l-0.3,6.6l-1.5,10
                l0,0l6.5,7.1l7.2,0.2l3.2-2l4.8,4.2l6.7-6.8l7.5,15.2l8.9,3.3l1.8,2.7l-0.5,6.1l-4.2,5.2v3.4l5.8,3.7l9-1.7l2.3,2.4l6.4-4.3
                l0.4-3.4l2.6-2.1l2.4,2l2.6-1.9l2.7,1.7l1.8-2.6l6.5,8.7l6.6-23.5l6.6,1.7l6-1.4l5.4,3.6h0.1l3.1-1.2l-1-5.9l3.2-5.6l-2.4-9.8
                l-2.8-1.8l5.6-4.2l-9.3-4.9l-0.5-3.4l1.2-4.5l7-7.6l2-9.3l1.3-1.4l-4.3-9.2l-3-1.6l5.3-4.6l-0.2-3.5l-2.3-3.5l-3,1.9l-5.4,2.1
                v-3.2l-3.4-1.7l-10.4-2.3l-2.5-5.3l3.1-2.8l-1.2-3.5L448.425,218.225z" />
            <path fill="#6b6b6b" class="FR-acad-04" :style="{ display: props.displayDep['FR-acad-04'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M470.125,237.325l3-1.9l2.3,3.5l0.2,3.5l-5.3,4.6l3,1.6l4.3,9.2l-1.3,1.4l-2,9.3l-7,7.6l-1.2,4.5l0.5,3.4
                l9.3,4.9l-5.6,4.2l2.8,1.8l2.4,9.8l-3.2,5.6l1,5.9l-3.1,1.2l4.7,8.7h3.3l0.1,3.4l3.3-0.1l4.5-5l3.8,1.8l0.5,3.2l4.3-0.3l6.1-3.6
                l5.3-7.3l-0.2-0.2l3-10.1l0.1-0.2l0.9-3.2l13.2-12.1l-1-10.9l9.7-6.7l13.1-16.2l-0.2-3.2l2.6-2.4l-4.8-4l1-3.4l0.3-0.2l4-4.5
                l0.3-0.1l0.1-0.1l4.4-0.5l-2.5-5.7l-3.1,0.2l-0.5-10.2l-8.5-5.6l-1.1,0.7l-9.4-7.4l-5.7,2.5l-5.5-4.1l-6.4,1.8l-7.9-4.7l-9.1,6.4
                l0.2,1.6l-2.5,3.6l-3.2-0.4l-2.4,2.7l-0.3,9.4l-3.4,1.1l-2.5-2l-7.4,4.4L470.125,237.325z" />
            <path fill="#6b6b6b" class="FR-acad-07" :style="{ display: props.displayDep['FR-acad-07'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M358.225,227.725l1.7-2.7l-1.2-3.3l5.2-5.5l-0.5-6.5l-7.5-8.4l-12.6,3l-10.3-0.8l3-1.7l0.6-3l-4.7-4.8
                l-0.8-3.5l-4.2-0.6l-3.1,1.9l-1.6-2.9l-2.2,3.1l-7.8,0.9l0,0l-3.2-11.9l-3.5,0.7l-2.4-2.4l-1.4-6.3l-7-6.1l-0.5-12.2l-4.1-7.4
                l-6.8,7.8l0.3,3.1l-6.7,1.5l-3.2-1.8l-6,3.6l-6.7,1.2l-2.6,2.8v3.7l5.4,4.9l-0.2,5l0.6,5.2l-8.4,5.8l1.1,8.4l0,0l2.4,4.3l-0.4,2
                v0.1l-2.3,2.3l1.9,2.5l0.4,6.3l-7,11.9l-3.6,1.2l-0.7,3.8v0.1l-8.6,5l-3.1-0.3l0.5,3.7l-6.8-2.9l-0.9,2.7l-1.8,10.4l-6.5,17.2v0.1
                l6.1,3.6l0.4,3.6l3.2-0.3l1.5,6.8l3.2,1.6l11.2-0.5l-1.6-3.2l6,3.3l0.2,3.5l10.3,14.7l-0.8,6.5l5.2,4.9h3.3l2.8,6.1l2.9,1.4
                l-1.7,3.1l2.9,1.3l6.5-1.2l1.9,2.7l3.2-3.2l12.8-1.2l2.2-2.8l14.8,3l3.1-0.9l5.5,0.2v-0.1l3.9-6.6l9.8-1.1l1.9-2.7l-1.9-6l2.5-2.1
                l9.5-3.3l5.4-4.1h4.5l1.5-10l0.3-6.6l-2.5-2.2l-0.3-6.6l-3.5-8.5l-3.1-2.4l1.9-6.5l-2.2-7.3l1.7-2.7l3.1-0.7l-2.4-7.8l-3.4-3.2
                L358.225,227.725z" />
            <path fill="#6b6b6b" class="FR-acad-01" :style="{ display: props.displayDep['FR-acad-01'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M382.825,305.425l-4.8-4.2l-3.2,2l-7.2-0.2l-6.5-7.1l0,0h-4.5l-5.4,4.1l-9.5,3.3l-2.5,2.1l1.9,6l-1.9,2.7
                l-9.8,1.1l-3.9,6.6v0.1l1.2,4.4c2.8,1.8,5.6,3.6,8.3,5.4l4.1,9.4l-0.6,4.6l2.6,7.6l-10.5,9.7l5,6.1l1.4,6.2l-2,2.8l2.2,15.7
                l-7-2.2l-0.7,3.8l-8.5,10.7l2,2.5l-2.6,2.1l-0.3,3.9l-3.5,1.3l1.7,3.7l-3.7,2.1l1.1,6.2l4.3,6.7l-2,6.5l3.7,5.6l5.8-3.6l7.1,1.8
                l4.3-5.5l3.4-10.2l5.3-4.8l5.9,4.6l1.4,4.7l2.7,1.8l-0.6,3.4l2.9,5.7l7.7-17.8l2.1,2.5l8.7-7.5l2.6,1.9l1.6,6.4l7.2,1.4l0.2-3.2
                l3.2,0.3l10.1,8.6l7.5-7.2l6.7-2.1l3.8-6.7l3.8-0.4l-0.8-3.2l3-2.5l1.7-5.9l2.9,1.4v-3.5l1-4.7l-2.5-2.6l-3,1.1l-0.7-6.2l-6.7-2.5
                l-7.1,3.4l-3.1,0.5l-2.6-2.3l-3.1,0.9l3.3-8l-3-8.4l-5.1-4.2l-5.3-8.7l2.4-7l-2.8-3.1l6.2-5.3l-1.7-14.1l0.5-3.3l4.4-1.5l4.2-5.2
                l0.5-6.1l-1.8-2.7l-8.9-3.3l-7.5-15.2L382.825,305.425z" />
            <path fill="#6b6b6b" class="FR-acad-22" :style="{ display: props.displayDep['FR-acad-22'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M325.525,321.925L325.525,321.925l-5.5-0.2l-3.1,0.9l-14.8-3l-2.2,2.8l-12.8,1.2l-3.2,3.2l-1.9-2.7
                l-6.5,1.2l-2.9-1.3l-1-0.3l-1.4,2.7l-3.8-0.9l-1.3,3.3l-6.4,3.1l-3.3,5.6l-0.2,9.6l5.2,4l-0.7,3.6l-2.9,1.2l-4.5,9l-3,0.7
                l-3.9,4.3h0.1l7,3.4l-0.1,5.3l10.7-1.3l8,6.3l-2.3,3.1l6.5,2.4l3.1,5.3l-4,5.2l2,3.3l-2.3,2l2.6,1.7l0.2,3.4l3.7,0.8l3.8,7.8
                l9.2-0.8l7.5,6.8l5.9-3l7.6,0.1l3.7-2.1l-1.7-3.7l3.5-1.3l0.3-3.9l2.6-2.1l-2-2.5l8.5-10.7l0.7-3.8l7,2.2l-2.2-15.7l2-2.8
                l-1.4-6.2l-5-6.1l10.5-9.7l-2.6-7.6l0.6-4.6l-4.1-9.4c-2.7-1.8-5.5-3.6-8.3-5.4L325.525,321.925z" />
            <path fill="#6b6b6b" class="FR-acad-03" :style="{ display: props.displayDep['FR-acad-03'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M470.225,317.425h-0.1l-5.4-3.6l-6,1.4l-6.6-1.7l-6.6,23.5l-6.5-8.7l-1.8,2.6l-2.7-1.7l-2.6,1.9l-2.4-2
                l-2.6,2.1l-0.4,3.4l-6.4,4.3l-2.3-2.4l-9,1.7l-5.8-3.7v-3.4l-4.4,1.5l-0.5,3.3l1.7,14.1l-6.2,5.3l2.8,3.1l-2.4,7l5.3,8.7l5.1,4.2
                l3,8.4l-3.3,8l3.1-0.9l2.6,2.3l3.1-0.5l7.1-3.4l6.7,2.5l0.7,6.2l3-1.1l2.5,2.6l3.9-0.8l3.6-5.5l5.6-3v-5l-0.2-1.2l0,0l5-4.5
                l-2.1-2.8l2.8-2.8l7.8-1.7l2.8-5.5l-0.4-8l6.8,2.2l3.4-5.7l3.2-0.8l12.4,17.1l6.5-7.6l2.5-15.2v-0.1l-0.8-10.4h3.2l3.1-3.2h-0.3
                v-0.1l0.6-5.6l6.5-4l1.2-7.8l-3.9-2.2l-5.3,7.3l-6.1,3.6l-4.3,0.3l-0.5-3.2l-3.8-1.8l-4.5,5l-3.3,0.1l-0.1-3.4h-3.3
                L470.225,317.425z" />
            <path fill="#6b6b6b" class="FR-acad-02" :style="{ display: props.displayDep['FR-acad-02'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M459.325,375.125l-7.8,1.7l-2.8,2.8l2.1,2.8l-5,4.5l0,0l0.2,1.2v5l-5.6,3l-3.6,5.5l-3.9,0.8l-1,4.7v3.5
                l-2.9-1.4l-1.7,5.9l-3,2.5l0.8,3.2l-3.8,0.4l-3.8,6.7l-6.7,2.1l-7.5,7.2l2.3,10.2l7.9,16l8.7,5.6l7.9-4.9l0.2,3.5l2.8-2.6h3.1
                l6.8,3.9l-0.1-4.1l6.5,0.7l4,4.8l9.7-4.4l2.7,1.6l2.9-2.5l0.5,6.5l10.5,1l0.2,3.2l5.8,2.5l4.8-5.3l2.6-0.1l1.1-0.3l0.2-5.2
                l-11.2-6.4l-1.7-3l3.7-5.7l4.6,1.6l2.8-2.9l-3.3-2.4l2.8-7.6l6.4-0.4l0.4-3.8l1.1-3.5l6.1-1.3l1.1-3.2l14.1-4.3l3.5,1.2l0.4-6.5
                l-4.9-3.5l-2-4.7l1.8-4.3l8.7,4l2.2-2.3l6.9-1.3v-0.1l21.6-9.3l2.3-4l-1.3-3l3.1-5.8l-8.2-5.7l-2.5-6.2l0.8-3.5l-6.3-2.6l-3.2-3
                l-0.6-3.4v-0.2l0.2-0.2l9.3-9l1.1-3.5l-3.8-5.1l-8.8-8.3l2.7-7l-4.2-6l0.5-3.2l-3.7-0.8l-9.5,0.7l-5.6,4.7l-3.9-1.7l-3.2,6.6
                l2.7,3.4l-7.8,6l-7.6,1.1l-3.1,3.2h-3.2l0.8,10.4v0.1l-2.5,15.2l-6.5,7.6l-12.4-17.1l-3.2,0.8l-3.4,5.7l-6.8-2.2l0.4,8
                L459.325,375.125z M463.725,460.525l-2.7,4l-4.5,1.4l-2.2-2.5l1.4-3.5l3-2.5L463.725,460.525z" />
            <!-- <path fill="#6b6b6b" :style="{display:props.displayDep['FR-acad-01']}" @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)" @mouseleave="onleave($event)" d="M285.425,69.425l-0.2,0.3l-12.2,8.5l-24.8,6.2l-16,8.6l-6.5,14.2l1.9,2.5l9.9,2.2l0.3,0.1l-1.8,0.6v0.1
                l0.4,9.8h3.1l-2.5,2.4l3.1,10.5l-1.6,3.3l2.6,4.7l-0.7,2.5l0.1,3.5l7.1,0.6l8.8,10.7l-1.9,2.5l3.9,2.3l2.6-2.8l6.7-1.2l6-3.6
                l3.2,1.8l6.7-1.5l-0.3-3.1l6.8-7.8l-2-6.6l2.6-2.8h4.2l4.5-10.6l4.5-2.9l-4-9.4l1.5-9l-1.5-9.9l3.5-5.2l-3.9-9.5L285.425,69.425z"
                /> -->
            <path fill="#6b6b6b" class="FR-acad-20" :style="{ display: props.displayDep['FR-acad-20'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)"
                d="M202.425,118.225l-14.1-1.5l-10.7-3.7l-5.8,2.5v0.1l-0.2,0.1l-7.2-12l2.7-8.7l-1.1-3.1l-7.3-0.8l-2.8,2
                l-7.3,0.5l-12.8-5.2l-0.2,3.1l3.7,2.1l0.5,5.5l-2.1,2.3l2.9,11.5l9.5,10.2l-1.5,11.3l2.2,3.1l-0.6,6.8l-2.5,6.4l4.6,10.6l3,1.4
                l2.8-1.8l-1.1,3.3l-8,0.8l5.4,10.8l3,1l7.9-5.3l6.2,2.6l9.7,0.9l3.7,4.1l2.7-1.7l2.8,1.9l7.6-3.7h10.2l0.8-3l3.1,0.2l2.2,6.8
                l3.6,1.7v3.3l3.4,0.1l12.3-6.5l3.1,1.7l1.8,9.4l8.1,5.6l3.2-1.1l3.6,3.9l3.5,0.8l0,0l-1.1-8.4l8.4-5.8l-0.6-5.2l0.2-5l-5.4-4.9
                v-3.7l-3.9-2.3l1.9-2.5l-8.8-10.7l-7.1-0.6l-0.1-3.5l0.7-2.5l-2.6-4.7l1.6-3.3l-3.1-10.5l2.5-2.4h-3.1l-0.4-9.8h-0.2l-7.7,1.8
                l-5.4,4.7l-10.9,3L202.425,118.225z M285.425,69.425l-0.2,0.3l-12.2,8.5l-24.8,6.2l-16,8.6l-6.5,14.2l1.9,2.5l9.9,2.2l0.3,0.1l-1.8,0.6v0.1
                l0.4,9.8h3.1l-2.5,2.4l3.1,10.5l-1.6,3.3l2.6,4.7l-0.7,2.5l0.1,3.5l7.1,0.6l8.8,10.7l-1.9,2.5l3.9,2.3l2.6-2.8l6.7-1.2l6-3.6
                l3.2,1.8l6.7-1.5l-0.3-3.1l6.8-7.8l-2-6.6l2.6-2.8h4.2l4.5-10.6l4.5-2.9l-4-9.4l1.5-9l-1.5-9.9l3.5-5.2l-3.9-9.5L285.425,69.425z" />
            <path fill="#6b6b6b" class="FR-acad-06" :style="{ display: props.displayDep['FR-acad-06'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M165.325,171.525l-7.9,5.3l-3-1l-5.4-10.8h-0.2l-12.6,0.1l0.3-6.3l-3.7,0.8l-4.5,4.3l2.7,5.8l0.2,0.4v0.1
                l-2.6-2.1l-0.1-0.1l-1.9-4.9h-3l-0.2,1.9v0.2l-4.6,0.4l-1.1-3.5l-3.4,1.6l-1.2-2.9l-4,0.3l-12.1,10l-1-4.1l-4.5-2.9l-0.1-3.5
                l-5.1-8.8l-3.3-0.7l0.7-3.2l-3.4,0.4l-1.8,3.4l1.4-7.5l-5.3,3.6l-0.6-4l-10,4.1l-1.6-2.7l-5,4.1l-0.3,6.9l-3.6,0.6h-0.2l-5.4-3.4
                l-3.2,0.7l-0.7,3.8l-2.5-3l-1.8,3.6l-0.1-7.3l-16.2,4.8l-1.7-2.9l-11.4,6.2l2.9,1.5l-9.4-0.9l-2.1,2.7l-1.6,10l2,2.6l3-1.2l3,1.3
                l13.8-4.1l-4.2,2l-0.6,3.8l6.7-1.7l-0.5,3.3l3.3,1.4l-3.5,0.7l7,3l-6.6-1.6l-1.5-2.8l-4.3,1.2l-6.5-1.3l-1.9-2.7l-1.7,4.1l2.8,5.9
                l1.9-3.1l3.1,0.3l6.1,3.5l1,3.6l-1.8,2.8l-2.8-1.5l-14.1,1.4l-1.7,2.8l10.7,4l4.1,7l-1.1,6.2l8.2,0.7l4.1-4.7v-0.4v-0.1l-0.9-3.2
                l1.2-0.2l0.4-3.5l1.6,3.2l-2,0.3l-0.3,3.5l3.4,2.2l5.1-1.9l5.3,6.7l3.2-0.7v-3.2l0.4,3.1v0.1l2.9-1.1l-0.7,3.2l3.3,0.7l4.2-1.7
                l0.2-3.4l0.8,8.6l2.9,1.8l3-1.5v-0.1l-0.5-6.5l0.6,3.4l3.3,0.6l-2.4,2.4l1.7,3l4.1,1.7l3-1.3l-2.7,1.4l0.3,3.1l2.4,3.2l-0.9,4.1
                l2.4,2.6l-1.4-3.2l3.1-2.7l3.6-0.8l2.8,2l-1.4-6.6l3.4,5.5l1.7-2.9l4.2,0.7l2.3,2.7l-2.2,2.8l-7-0.9l5.3,4.2l13.9-1.1l3.1,1.7
                l-3.4,0.2l1.8,2.9l17.3-5.1l0.4-6.7l5.9-3.8l14.7-0.5l1.8-3.2l10.1-4.4l7.7,4l0.5-2.4l4-9.7l6.2-2.5l-2.1-19.5l1.7-2.6l0.1-13.6
                L165.325,171.525z" />
            <path fill="#6b6b6b" class="FR-acad-26" :style="{ display: props.displayDep['FR-acad-26'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M184.925,179.125l-3.7-4.1l-9.7-0.9l-0.1,13.6l-1.7,2.6l2.1,19.5l-6.2,2.5l-4,9.7l-0.5,2.4l-7.7-4
                l-10.1,4.4l-1.8,3.2l-14.7,0.5l-5.9,3.8l-0.4,6.7l-17.3,5.1l-5,4.9l2.7,6.9l6.8,1.1l2.3,2.3l5.6-4.1l8-0.7l-7.6,2.9l-0.7,7.4
                l-3.4,1.5l6.7,2.4l5.4,5.6l-8.8,9.3l0.5,5l11.7,13.7l2.3,7.9l9.1,7.6l5.4,1.2l1.8,4l6.7,1.3l5.3,4.3l0.7-3.1l3.2,0.4l9.9-3.8
                l-1.5,3.3l7.2-0.3l2.7,2l10.2-5.1l-2.8-2.1l0.3-8.4l-5.4-20.1l-4.7-4.7l-2.5-6.3l13.7-1l4.2-5.3l6.3-0.8l10.9-0.3l2.5,2.6l7.5-6.7
                v-0.1l6.5-17.2l1.8-10.4l0.9-2.7l6.8,2.9l-0.5-3.7l3.1,0.3l8.6-5v-0.1l0.7-3.8l3.6-1.2l7-11.9l-0.4-6.3l-1.9-2.5l2.3-2.3v-0.1
                l0.4-2l-2.4-4.3l-3.5-0.8l-3.6-3.9l-3.2,1.1l-8.1-5.6l-1.8-9.4l-3.1-1.7l-12.3,6.5l-3.4-0.1v-3.3l-3.6-1.7l-2.2-6.8l-3.1-0.2
                l-0.8,3h-10.2l-7.6,3.7l-2.8-1.9L184.925,179.125z" />
            <path fill="#6b6b6b" class="FR-acad-23" :style="{ display: props.displayDep['FR-acad-01'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M199.525,273.325l-6.3,0.8l-4.2,5.3l-13.7,1l2.5,6.3l4.7,4.7l5.4,20.1l-0.3,8.4l2.8,2.1l-10.2,5.1l-2.7-2
                l-7.2,0.3l1.5-3.3l-9.9,3.8l-5.1,10.9l3.3,1l4.6,9l-3.1,0.5l1.8,6l-3.3,6.5l-5.3,3.7l1.4,4.7l8.8,5.5l11.7,12.6l2.4,8.4l6.6-0.9
                l0.7,3.2l7.2,1.9l0.6,6.5l6.9,4.8l10.6,1.1l0,0l2.3-5.5v-0.6l0,0h0.1l2.2-2.1l6.2-0.9l4.5-6.4l0.9-6.5l8.8-6.6l2.4-5.9l6.4-6.9
                h-0.1l3.9-4.3l3-0.7l4.5-9l2.9-1.2l0.7-3.6l-5.2-4l0.2-9.6l3.3-5.6l6.4-3.1l1.3-3.3l3.8,0.9l1.4-2.7l1,0.3l1.7-3.1l-2.9-1.4
                l-2.8-6.1h-3.3l-5.2-4.9l0.8-6.5l-10.3-14.7l-0.2-3.5l-6-3.3l1.6,3.2l-11.2,0.5l-3.2-1.6l-1.5-6.8l-3.2,0.3l-0.4-3.6l-6.1-3.6l0,0
                l-7.5,6.7l-2.5-2.6L199.525,273.325z" />
            <path fill="#6b6b6b" class="FR-acad-21" :style="{ display: props.displayDep['FR-acad-21'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M245.525,369.925L245.525,369.925l-6.4,6.9l-2.4,5.9l-8.8,6.6l-0.9,6.5l-4.5,6.4l-6.2,0.9l-2.2,2.1h-0.1
                v0.6l-2.3,5.5l0,0l-10.6-1.1l-6.9-4.8l-0.6-6.5l-7.2-1.9l-0.7-3.2l-6.6,0.9l0.6,3.1l-2.4,3.8l-2.8-8.8l-12-11.8l-1.1-3.2l-3.3,6.5
                l-6.9,56.3l4.4-8.7l5,5.8l0.3,3.2l-7.2-1l-3.6,7.7l0.1,5.3l-12.4,56.2l-4.2,8l-5.2,7.1l-7.1,5.2l6.5,3l0.6,3.5l2.5-2.3l7.4,1.5
                l1.6,3.2l-2.1,6.6l-3,2.6l1.5,3.2l4.4,1l0.5-4l3.3-1l0.3,3.7l6.5,3.6l10.9,4.2l7-0.4l3,5.3l8.8,7.2l2.6-1.9l2.6,1.6l6.7-3.4h0.1
                l1.4-9.7l1.8-3.1l3.8-0.9l-0.5-3.3l9.1-10.4l-0.9-3.5l3-1.5l-0.6-8.2h-3.2l1.6-3.1l-2.7-6.5l-0.1-0.1l-7.4-0.3l-1.7-3l4.7-12.5
                l-0.5-7.1l10.4-3.1l0.8,3.4l2.8-1.5l0.4-3.3v-0.1l4.2,0.8l1.5-2.8l9.4,0.8l5.6-3.2l9.6,1.1l3-1.5l4.1-5.3l3.1,0.3l-1.5-3.1l5-6.2
                l-2.8-1.5v-3.3l8.5-2.2l-3-10.3l3.9-3.5l5.1-7.2l6.9-3.9l-0.7-4l5.1-3.6l1.5-6l0.7-5.6l-3.8-7.8l-3.7-0.8l-0.2-3.4l-2.6-1.7l2.3-2
                l-2-3.3l4-5.2l-3.1-5.3l-6.5-2.4l2.3-3.1l-8-6.3l-10.7,1.3l0.1-5.3L245.525,369.925z" />
            <path fill="#6b6b6b" class="FR-acad-25" :style="{ display: props.displayDep['FR-acad-25'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M284.425,418.625l-0.7,5.6l-1.5,6l-5.1,3.6l0.7,4l-6.9,3.9l-5.1,7.2l-3.9,3.5l3,10.3l-8.5,2.2v3.3l2.8,1.5
                l-5,6.2l1.5,3.1l-3.1-0.3l-4.1,5.3l-3,1.5l-9.6-1.1l-5.6,3.2l-9.4-0.8l-1.5,2.8l-4.2-0.8v0.1l-0.4,3.3l-2.8,1.5l-0.8-3.4
                l-10.4,3.1l0.5,7.1l-4.7,12.5l1.7,3l7.4,0.3l0.1,0.1l2.7,6.5l-1.6,3.1h3.2l0.6,8.2l-3,1.5l0.9,3.5l-9.1,10.4l0.5,3.3l-3.8,0.9
                l-1.8,3.1l-1.4,9.7v0.1l12.6,11.2l13.7-1.9l2.8,2.3l18.2,0.3l2.5-2.5l-0.4-7.4l2.8-1.4l18.7,5.5l5.4,4.9l7.1-0.5l6.5,8.5l1.2-3.3
                l3.7-0.2l11.9,5.7l-0.1,0.1l10-3l1.4-2.9l8.3-0.2l-5.4-6.3l-3.7,1.3l-3.8-1.6l-1-6.5l3.7-0.7l1.4-3.3l-2.8-2.8l2.5-2.6l-0.2-3.4
                l-2.5-6l-9.7-4.3l-2.6-6.2l9.5-11.2l1.5,3l6-2l0.4-0.9l2,2.6l7.1,1l1.3-3.7l3.2-0.5l13.4,1.5l3.8-2.3l1-3.3l-0.8-10.9l6.8,2.4
                c2.6-1.6,5.1-3.2,7.7-4.8l6.3,0.1l0.2-9.8l7,1.4l3.4-6l4.2-1.1l-0.4-1.7l4.9-5.9l-9.4-4.6l3-1.3l2.3-4.3l-10.5-5l-1.8-2.8l0.7-9.7
                l-3.3-9.9l-4.8-5.1v-0.1l-2.9-5.7l0.6-3.4l-2.7-1.8l-1.4-4.7l-5.9-4.6l-5.3,4.8l-3.4,10.2l-4.3,5.5l-7.1-1.8l-5.8,3.6l-3.7-5.6
                l2-6.5l-4.3-6.7l-1.1-6.2l-7.6-0.1l-5.9,3l-7.5-6.8L284.425,418.625z" />
            <path fill="#6b6b6b" class="FR-acad-24" :style="{ display: props.displayDep['FR-acad-24'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M297.825,545.725l9.7,4.3l2.5,6l0.2,3.4l-2.5,2.6l2.8,2.8l-1.4,3.3l-3.7,0.7l1,6.5l3.8,1.6l3.7-1.3
                l5.4,6.3l-8.3,0.2l-1.4,2.9l-10,3l-2,5.3l9.8,3l2.6,6.4l3.7,0.2l4.9-4.5l3.6-0.2l10.6,2.4l5.1,4l7.1-0.5v-3.3l9.1-4.8l7-1.2
                l9.7,2.6l-6.5-8.1l-0.4-19.7l0.5-11.1l6.3-12l3-3.1l6.1-4.5h7.7l7.4-8.2l12.3-8.9l9.4-2.6l0.1,0.1l0,0l3.2,5.4l3.4,0.8h0.2
                l4.3-6.7l6.5-1.9l-1.1-3.1l4.7-5.4l3.7,1l2.1-11.9l9.5-10.2l-7.1-7.2l-0.2-6.1l-2.9-5.1l-6.8-3.9h-3.1l-2.8,2.6l-0.2-3.5l-7.9,4.9
                l-8.7-5.6l-7.9-16l-2.3-10.2l-10.1-8.6l-3.2-0.3l-0.2,3.2l-7.2-1.4l-1.6-6.4l-2.6-1.9l-8.7,7.5l-2.1-2.5l-7.7,17.8v0.1l4.8,5.1
                l3.3,9.9l-0.7,9.7l1.8,2.8l10.5,5l-2.3,4.3l-3,1.3l9.4,4.6l-4.9,5.9l0.4,1.7l-4.2,1.1l-3.4,6l-7-1.4l-0.2,9.8l-6.3-0.1
                c-2.6,1.6-5.1,3.2-7.7,4.8l-6.8-2.4l0.8,10.9l-1,3.3l-3.8,2.3l-13.4-1.5l-3.2,0.5l-1.3,3.7l-7.1-1l-2-2.6l-0.4,0.9l-6,2l-1.5-3
                l-9.5,11.2L297.825,545.725z" />
            <path fill="#6b6b6b" class="FR-acad-27" :style="{ display: props.displayDep['FR-acad-27'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M553.625,444.225l1.5-6.7l0.3-0.4l2-2.3l4,0.1l-4.3-10.5l-6.8-0.3l-5.8-3.8l-0.9-7.4l-3.1-0.1l-2.6-5.8
                l0,0l-6.9,1.3l-2.2,2.3l-8.7-4l-1.8,4.3l2,4.7l4.9,3.5l-0.4,6.5l-3.5-1.2l-14.1,4.3l-1.1,3.2l-6.1,1.3l-1.1,3.5l-0.4,3.8l-6.4,0.4
                l-2.8,7.6l3.3,2.4l-2.8,2.9l-4.6-1.6l-3.7,5.7l1.7,3l11.2,6.4l-0.2,5.2l-1.1,0.3l-2.6,0.1l-4.8,5.3l-5.8-2.5l-0.2-3.2l-10.5-1
                l-0.5-6.5l-2.9,2.5l-2.7-1.6l-9.7,4.4l-4-4.8l-6.5-0.7l0.1,4.1l2.9,5.1l0.2,6.1l7.1,7.2l-9.5,10.2l-2.1,11.9l-3.7-1l-4.7,5.4
                l1.1,3.1l-6.5,1.9l-4.3,6.7l16.1,0.5l2.2,2.8l-0.6,3.1l4.2,0.9l10.9-1.1l-2.5-2.7l1.4-2.8l3.1,0.3l5.1,6.1l14.9-1.7l4.4,9.9
                l10.9,2.6l3.1-0.9l0.2,0.2l0.4-3.8l2.9-2.2l-2.6-7l1.3-3l3.1-0.5l-6.2-10.2l2.2-6l3.7-0.9l-2.2-4.3v-0.1l0.2-0.4l5.9,0.3l3.1-2
                l4.4,5.4l11.4-9.5l6.6,4l1.4-3.9l10.9-0.5l3.5-1.6l-1.5-2.9l5.9-4.4l5.8,0.8l-7.8-8.7l-4.8-9.5l2.2-6.1l6.9-7.7l-0.2-0.2l2.1-5.5
                l-4.4-6.1L553.625,444.225z" />
            <path fill="#6b6b6b" class="FR-acad-28" :style="{ display: props.displayDep['FR-acad-28'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M567.625,467.725l-7.1-1.4l-6.9-8.3l-6.9,7.7l-2.2,6.1l4.8,9.5l7.8,8.7l-5.8-0.8l-5.9,4.4l1.5,2.9
                l-3.5,1.6l-10.9,0.5l-1.4,3.9l-6.6-4l-11.4,9.5l-4.4-5.4l-3.1,2l-5.9-0.3l-0.2,0.5l2.2,4.3l-3.7,0.9l-2.2,6l6.2,10.2l-3.1,0.5
                l-1.3,3l2.6,7l-2.9,2.2l-0.4,3.8l6.9,4l-0.4,3.1l10-2.9l6.7,1.8l1.3,3.2l2.2-6.2l8.4,1.4l0.1-3.2l6.3-1.4l3-2.8l4.2,0.4l2.5-6.1
                l-3.9-1.8l6.9-9.2l6-0.8l2.2-3.7l0.1-0.2l2.6-4.7l6.5-3l1.2-4.7l16.2-8.8l0.5-8.2l7-9.6l2.3-4.9l-4.5-6l-13.4,4.8L567.625,467.725
                z" />
            <path fill="#7E6078" class="FR-acad-08" :style="{ display: props.displayDep['FR-acad-08'] }"
                @click="onclick($event)" @dblclick="ondblclick()" @mouseenter="onenter($event)"
                @mouseleave="onleave($event)" d="M561.625,556.825l0.5,19.5l-9.6-1.5l-4,5.5l-12,4.8l-1,3.2l-3.1,0.3l-2.5,3.2l0.4,3.8l-4.4,5.6l-0.6,3.3
                l2.9-1.1l4.3,4.7l-5.9,4.2l1.2,6.2l8.8,4.9l-2.9,1.9l-3.3,9.4l8.1-2.9l1,6.5l-2.9,7.9l10.5,2.1l-5.4,3.7l-0.2,3.3l5.4,5l12.5,3.8
                l1.4,3.1l3.5,1.4l6.5-16.5l-3.7-0.4l3-2.2l2.3-6.1l-0.8-16.6l6-13.4l-4.2-29.2l-4.5-6.4l0.6-11.9l-2-10.2l-3.2-1.2
                L561.625,556.825z" />
        </g>
    </svg>
</template>
