<template>
  <section class="dropdown-wrapper">
    <div @click="
      isVisible = !isVisible;
    emitOpen(isVisible);
    " class="selected-item fr-input">
      <span v-if="selectedItem" data-fr-analytics-click="selected-sites">{{
      selectedItem["label"]
    }}</span>
      <span v-else id="all-sites-label" data-v-853640ee="" data-fr-analytics-click="all-sites"
        data-fr-js-attribute-actionee="true">Tous les sites</span>
      <svg class="drop-down-icon" :class="isVisible ? 'dropdown' : ''" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
        <path
          d="M11.9999 10.8284L7.0502 15.7782L5.63599 14.364L11.9999 8L18.3639 14.364L16.9497 15.7782L11.9999 10.8284Z">
        </path>
      </svg>
    </div>
    <div :class="isVisible ? 'visible' : 'invisible'" class="dropdown-popover fr-select-group" role="compobox"
      aria-expanded="false">
      <input class="fr-input" type="text" v-model="searchQuery" placeholder="Rechercher" />
      <span v-if="filteredItem.length === 0">Pas de résultats</span>

      <div class="options">
        <ul>
          <li id="chosen-site-label" data-v-853640ee="" data-fr-analytics-click="chosen-site"
            data-fr-js-attribute-actionee="true" v-for="item in filteredItem" :key="item['value']"
            @click="selectItem(item)">
            {{ item["label"] }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      searchQuery: "",
      selectedItem: null,
      isVisible: false,
      SitesListe: [],
    };
  },
  props: {
    itemList: {
      type: Array,
      default: () => [],
      required: true,
    },
    resetFilter: {
      required: false,
    },
  },
  computed: {
    filteredItem() {
      const query = this.searchQuery.toLowerCase();

      if (this.searchQuery === "") {
        return this.SitesListe;
      }

      return this.SitesListe.filter((item) => {
        return Object.values(item).some((word) =>
          String(word).toLowerCase().includes(query)
        );
      });
    },
  },
  watch: {
    itemList: {
      handler() {
        this.searchQuery = "";
        this.selectedItem = undefined;
        this.SitesListe = this.itemList;
      },
      deep: true,
    },
    resetFilter: {
      handler() {
        if (this.resetFilter === undefined) {
          this.searchQuery = "";
          this.selectedItem = undefined;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.SitesListe = this.itemList;
  },
  methods: {
    emitOpen(visible) {
      this.$emit("open", visible);
    },

    selectItem(item) {
      this.selectedItem = item;
      this.searchQuery = "";
      this.isVisible = false;
      this.$emit("open", this.isVisible);
      this.$emit("item-selected", item);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/components/searchDropdown.scss";
</style>
