import Vue from "vue";
import Router from "vue-router";
import dsfrAnalytics from "./services/dsfr_analytics";
import store from "./store/store";

Vue.use(Router);

let authenticated_required;
process.env.VUE_APP_KEYCLOAK_AVAILABLE === "true"
  ? (authenticated_required = true)
  : (authenticated_required = false);
const audienceCommunicationWebsiteLink = "Observatoire des sites Internet de l’État";

const router = new Router({
  mode: "history",
  base: process.env.VUE_APP_PREFIX_PATH,
  routes: [
    {
      path: "/sites",
      name: "sites",
      component: () =>
        import("./views/Sites.vue"),
      meta: {
        requiresAuth: authenticated_required,
        title: `Panorama - ${audienceCommunicationWebsiteLink}`,
        theme: 'Panorama',
        dsfrAnalytics: true,
      },
    },
    {
      path: "/visites",
      name: "visites",
      component: () =>
        import("./views/Visit.vue"),
      meta: {
        requiresAuth: authenticated_required,
        title: `Audience - ${audienceCommunicationWebsiteLink}`,
        theme: "Audience",
        dsfrAnalytics: true,
      },
    },
    {
      path: "/",
      name: "accueil",
      component: () =>
        import( "./views/Home.vue"),
      meta: {
        requiresAuth: authenticated_required,
        title: `Accueil - ${audienceCommunicationWebsiteLink}`,
        theme: 'Accueil',
        dsfrAnalytics: true
      },
    },
    {
      path: "/mentions-legales",
      name: "mentions-legales",
      component: () =>
        import("./views/LegalMentions.vue"),
      meta: {
        requiresAuth: authenticated_required,
        theme: 'Mentions legales',
        title: `Mentions légales - ${audienceCommunicationWebsiteLink}`,
        dsfrAnalytics: true
      },
    },
    {
      path: "/donnees-personnelles",
      name: "private-data",
      component: () =>
        import("./views/PrivateDataPage.vue"),
      meta: {
        requiresAuth: authenticated_required,
        title: `Données personnelles et cookies - ${audienceCommunicationWebsiteLink}`,
        theme: 'Donnees Personnelles',
        dsfrAnalytics: true
      },
    },
    {
      path: "/accessibilite",
      name: "accessibilite",
      component: () =>
        import("./views/AccessibilityPage.vue"),
      meta: {
        requiresAuth: authenticated_required,
        title: `Accessibilité - ${audienceCommunicationWebsiteLink}`,
        theme: 'Accessibilite',
        dsfrAnalytics: true
      },
    },
  ]
});

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

router.beforeEach(async (to, from, next) => {

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // We wait for Keycloak init, then we can call all methods safely
    while (router.app.$keycloak.createLoginUrl === null) {
      await sleep(100);
    }
    if (router.app.$keycloak.authenticated) {
      next();
    } else {
      const loginUrl = router.app.$keycloak.createLoginUrl();
      window.location.replace(loginUrl);
    }
  } else {
    next();
  }
});


router.beforeEach(async (to, from, next) => {
  if (to.meta.dsfrAnalytics) {
    await sleep(900);
    dsfrAnalytics({
      path: to.path,
      name: to.name,
      title: to.meta.title,
      theme: to.meta.theme,
    });
  }
  if (to.name === 'accueil' || to.name === 'sites') {
    await store.dispatch('setIsSidenavVisible', false);
  }
  await store.dispatch('setIsAudience', to.name === 'visites');
  next();
});

export default router;
