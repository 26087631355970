const state = {
    isComparisonMenuVisible: false,
    areDimensionsSegmentSelected: true,
    comparedSelectedSite: undefined,
    comparedSelectedMinistry: undefined,
    comparedSelectedTypeSite: undefined,
    comparedParams: {},
    comparedMinistries: [],
    comparedTypeSites: [],
    comparedSites: [],
    comparedSelectedPeriod: "",
    comparedPeriod: 31,
    comparedStartDatePicker: undefined,
    comparedEndDatePicker: undefined
}

const getters = {
    getComparisonMenuVisible: (state) => state.isComparisonMenuVisible,
    getDimensionsSegmentSelected: state => state.areDimensionsSegmentSelected,
    getComparedSelectedSite: state => state.comparedSelectedSite,
    getComparedSelectedMinistry: state => state.comparedSelectedMinistry,
    getComparedSelectedTypeSite: state => state.comparedSelectedTypeSite,
    getComparedParams: state => state.comparedParams,
    getComparedMinistries: state => state.comparedMinistries,
    getComparedTypeSites: state => state.comparedTypeSites,
    getComparedSites: state => state.comparedSites,
    getComparedSelectedPeriod: state => state.comparedSelectedPeriod,
    getComparedPeriod: state => state.comparedPeriod,
    getComparedStartDatePicker: state => state.comparedStartDatePicker,
    getComparedEndDatePicker: state => state.comparedEndDatePicker,
};

const actions = {
    setIsComparisonMenuVisible({commit}, value) {
        commit('SET_IS_COMPARISON_MENU_VISIBLE', value);
    },
    setAreDimensionsSegmentSelected({commit}, dimensions) {
        commit('SET_ARE_DIMENSIONS_SEGMENT_SELECTED', dimensions);
    },
    setComparedSelectedSite({commit}, site) {
        commit('SET_COMPARED_SELECTED_SITE', site);
    },
    setComparedSelectedMinistry({commit}, ministry) {
        commit('SET_COMPARED_SELECTED_MINISTRY', ministry);
    },
    setComparedSelectedTypeSite({commit}, typeSite) {
        commit('SET_COMPARED_SELECTED_TYPE_SITE', typeSite);
    },
    setComparedComparedParams({commit}, params) {
        commit('SET_COMPARED_PARAMS', params);
    },
    setComparedMinistries({commit}, ministries) {
        commit('SET_COMPARED_MINISTRIES', ministries);
    },
    setComparedTypeSites({commit}, typeSites) {
        commit('SET_COMPARED_TYPE_SITES', typeSites);
    },
    setComparedSites({commit}, sites) {
        commit('SET_COMPARED_SITES', sites);
    },
    setComparedSelectedPeriod({commit}, selectedPeriod) {
        commit('SET_COMPARED_SELECTED_PERIOD', selectedPeriod);
    },
    setComparedPeriod({commit}, period) {
        commit('SET_COMPARED_PERIOD', period);
    },
    setComparedStartDatePicker({commit}, startDatePicker) {
        commit('SET_COMPARED_START_DATE_PICKER', startDatePicker);
    },
    setComparedEndDatePicker({commit}, endDatePicker) {
        commit('SET_COMPARED_END_DATE_PICKER', endDatePicker);
    },
}

const mutations = {
    SET_IS_COMPARISON_MENU_VISIBLE(state, isComparisonMenuVisible) {
        state.isComparisonMenuVisible = isComparisonMenuVisible;
    },
    SET_ARE_DIMENSIONS_SEGMENT_SELECTED(state, dimensions) {
        state.areDimensionsSegmentSelected = dimensions;
    },
    SET_COMPARED_SELECTED_SITE(state, site) {
        state.comparedSelectedSite = site;
    },
    SET_COMPARED_SELECTED_MINISTRY(state, ministry) {
        state.comparedSelectedMinistry = ministry;
    },
    SET_COMPARED_SELECTED_TYPE_SITE(state, typeSite) {
        state.comparedSelectedTypeSite = typeSite;
    },
    SET_COMPARED_PARAMS(state, params) {
        state.comparedParams = params;
    },
    SET_COMPARED_MINISTRIES(state, ministries) {
        state.comparedMinistries = ministries;
    },
    SET_COMPARED_TYPE_SITES(state, typeSites) {
        state.comparedTypeSites = typeSites;
    },
    SET_COMPARED_SITES(state, sites) {
        state.comparedSites = sites;
    },
    SET_COMPARED_SELECTED_PERIOD(state, selectedPeriod) {
        state.comparedSelectedPeriod = selectedPeriod;
    },
    SET_COMPARED_PERIOD(state, period) {
        state.comparedPeriod = period;
    },
    SET_COMPARED_START_DATE_PICKER(state, startDatePicker) {
        state.comparedStartDatePicker = startDatePicker;
    },
    SET_COMPARED_END_DATE_PICKER(state, endDatePicker) {
        state.comparedEndDatePicker = endDatePicker;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};