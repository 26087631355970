<template>
  <div class="fr-grid-row">
    <div class="fr-col-12 fr-col-lg" v-if="data!=''">
      <map-chart
        :data="data"
        :level="geolevel"
        :valuenat="valueNat"
        :noMapped="noMapped"
        name="Nombre de visites"
        :date = updateDate
      >
      </map-chart>
    </div>
  </div>
</template>

<script>
import MapChart from '../componentsDsfr/MapChart.vue'
export default {
  name: 'MapBox',
  components: {
    MapChart
  },
  data () {
    return {
    }
  },
  props: {
    data: {
      type: String,
      required: true
    },
    geolevel: {
      type: String,
      required: true
    },
    valueNat: {
      type: Number,
      required: true
    },
    noMapped: {
      type: Number,
      required: true
    },
    updateDate:{
      type: String,
      required: true
    }
  }
}
</script>
