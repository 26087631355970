<template>
  <section v-if="this.isAudience" class="subContainer">
    <div class="fr-container--fluid">
      <div class="fr-grid-row fr-grid-row--gutters fr-px-3w fr-pt-3w fr-mb-4w">
        <div ref="content" class="fr-col-12 fr-col-lg-9 subContainer__titleSection">
          <div>
            <h1 id="frequentation" class="fr-mb-0">
              Fréquentation des sites de l’État
            </h1>
          </div>
          <div>
            <button class="fr-btn fr-btn--tertiary-no-outline subContainer__titleSection--icon" id="button-2995"
              aria-describedby="tooltip-2994">
              <span class="fr-icon-question-line" aria-hidden="true"></span>
            </button>
            <span class="fr-tooltip fr-placement" id="tooltip-2994" role="tooltip" aria-hidden="true">
              Interagissez avec les données d'audience des {{ nbSitesVisits }} sites de l'État qui les ont publiées,
              mises à jour tous les jours.
            </span>
          </div>
        </div>

        <div class="fr-col-12 fr-col-lg-3 subContainer__btn">
          <button class="fr-btn fr-mx-1v subContainer__btn--filter" @click="openSidenav" aria-expanded=true
            id="fr-btn--secondary-filter" title="Filtrer">
            <span class="fr-icon-filter-line subContainer__btn--filter--icon" aria-hidden="true"></span>
            Filtrer
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {endPoint, fetchDataNbSites} from "../services/api";

export default {
  name: "Visitors",
  data() {
    return {
      nbSitesVisits: 0,
      endpoint: endPoint
    }
  },
  computed: {
    ...mapGetters([
      'isAudience',
      'getMinistries',
      'getTypeSites',
      'getSites'
    ]),
  },
  methods: {
    ...mapActions([
        'setIsSidenavVisible'
    ]),
    openSidenav() {
      this.setIsSidenavVisible(true);
    },
    async getNbSites(kind) {
      try {
        let response = await fetchDataNbSites(this.endpoint.nbSites, kind);
        this.nbSitesVisits = response.data.nb_sites;
      } catch (error) {
        console.error("Error in fetchDataNbSites", error);
      }
    },
  },
  mounted() {
    this.getNbSites("display_visits_page");
  },
};
</script>

<style scoped lang="scss">
@import "../styles/components/visitors.scss";
</style>