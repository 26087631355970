<template>
    <div class="fr-table fr-table--bordered fr-table--layout-fixed fr-table--no-caption fr-p-1w fr-tab-data">
        <table>
            <caption>{{ captionTitle }}</caption>
            <thead>
                <tr>
                    <th scope="col" v-for="(name) in table.columns_names" :key="name">{{ name }}</th>
                </tr>
            </thead>
            <tbody v-if="table.columns_names.length===2">
                <tr v-for="(index) in table.matrix[0].length" :key="index">
                    <td v-for="col in [0, 1]" :key="col">
                        {{ table.matrix[col][index - 1] }}</td>
                </tr>
            </tbody>
            <tbody v-else-if="table.columns_names.length===3">
                <tr v-for="(index) in table.matrix[0].length" :key="index">
                    <td v-for="col in [0, 1, 2]" :key="col">
                        {{ table.matrix[col][index - 1] }}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="(index) in table.matrix[0].length" :key="index">
                    <td v-for="col in [0, 1, 2,3]" :key="col">
                        {{ table.matrix[col][index - 1] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'TableComponent',
    props: {
        captionTitle: {
            type: String,
            required: true,
            default: ''
        },
        table: {
            type: Object,
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">
@import "../styles/components/tableComponent.scss";
</style>
