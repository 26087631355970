<template>
  <div class="fr-container" :id="widgetId">
    <div class="fr-grid-row">
      <div class="fr-col-12 fr-col-sm-3">
        <p class="fr-text--xs fr-text-mention--grey fr-mb-3v">Localisation</p>
        <p class="fr-text--sm fr-text--bold fr-mb-3v"> {{ pays }} </p>
        <p class="fr-text--xs fr-text-mention--grey fr-mb-3v">Mise à jour : {{ updateDate }}</p>
        <p class="fr-text--sm fr-text--bold fr-mb-3v"> Nombre de visites </p>
        <p class="fr-text--sm fr-text--bold fr-mb-3v"> {{ convertFloatToHuman(totalCountry) }} </p>
        <p class="fr-text--xs fr-mb-1v"> dont {{ convertFloatToHuman(noMapped) }} sans correspondance</p>
      </div>
      <div class="fr-col-12 fr-col-lg">
        <WorldMap :props="{
    viewBox: this.mapDragOffsetX + ' ' + this.mapDragOffsetY + ' ' + (1900 * zoomScale) + ' ' + (1200 * zoomScale),
    fill: this.countryColors
  }" :onenter="showCountryTooltip" :onleave="hideCountryTooltip"></WorldMap>
      </div>
      <div class="tooltip_inetr" ref="tooltip"
        :style="{ top: tooltip.top, left: tooltip.left, visibility: tooltip.visibility }">
        <p class="tooltip_header_inter">{{ tooltip.place }}</p>
        <p class="tooltip_value_inter"> {{ convertStringToLocaleNumber(tooltip.value) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import WorldMap from '@/components/maps/World'
import * as d3 from 'd3'
import { isMobile } from 'mobile-device-detect'
import { mixin } from '../../utils.js'

export default {
  name: 'MapWorldChart',
  mixins: [mixin],
  components: {
    WorldMap
  },
  data() {
    return {
      tooltip: {
        top: '0px',
        left: '0px',
        visibility: 'hidden',
        value: 0,
        place: ''
      },
      WorldProps: {
        viewBox: '0 0 1900 1000',
        fill: this.countryColors
      },
      selected: null,
      zoomScale: 0.6,
      mapDragging: false, // Indicateur de glissement de carte
      mapDragStartX: 0, // Position de départ X du glissement
      mapDragStartY: 0, // Position de départ Y du glissement
      mapDragOffsetX: 0, // Offset X du glissement
      mapDragOffsetY: 0,
      pays: '',
      visits: ''
    }
  },
  props: {
    dataCountry: {
      type: Object,
      required: true
    },
    updateDate: {
      type: String,
      required: true
    },
    totalCountry: {
      type: Number,
      required: true
    },
    noMapped: {
      type: Number,
      required: true
    }
  },
  methods: {
    showCountryTooltip(event)
    {
      if (isMobile) return
      const countryName = event.target.getAttribute('name')
      const countryId = event.target.getAttribute('id')
      this.pays = countryName
      event.target.getBoundingClientRect();
      const tooltip = this.$refs.tooltip
      this.tooltip.value = this.dataCountry[countryId]
      this.tooltip.place = countryName;
      this.tooltip.left = event.layerX + 55 + 'px'
      this.tooltip.top = event.layerY + 125 + 'px'
      this.tooltip.visibility = 'visible'
      const path = document.getElementById(countryId);
      path.setAttribute('fill', this.countryColorsHover[countryId])
    }
,
    hideCountryTooltip() {
      const countryId = event.target.getAttribute('id')
      const path = document.getElementById(countryId)
      path.setAttribute('fill', this.countryColors[countryId])
      const tooltip = this.$refs.tooltip
      this.tooltip.visibility = 'hidden'
    },
// Fonction pour commencer le glissement de la carte
    startMapDrag(event) {
      this.mapDragging = true
      this.mapDragStartX = event.clientX
      this.mapDragStartY = event.clientY
    },

    // Fonction pour mettre à jour la position de la carte en fonction du glissement
    dragMap(event) {
      if (this.mapDragging) {
        const offsetX = event.clientX - this.mapDragStartX;
        const offsetY = event.clientY - this.mapDragStartY;
        const minX = -1500; // Minimum limit in X
        const minY = -1500; // Minimum limit in Y
        const maxX = 1500; // Maximum limit in X
        const maxY = 1500; // Maximum limit in Y

        // Update the mapDragOffsetX and mapDragOffsetY based on the drag
        this.mapDragOffsetX = Math.min(Math.max(this.mapDragOffsetX - offsetX, minX), maxX);
        this.mapDragOffsetY = Math.min(Math.max(this.mapDragOffsetY - offsetY, minY), maxY);

        // Update the drag start position for the next iteration
        this.mapDragStartX = event.clientX;
        this.mapDragStartY = event.clientY;
      }

    },
  },
  computed: {

    countryColors() {
      const colorScale = d3.scaleLog()
        .domain([1, Math.max(...Object.values(this.dataCountry))])
        .range(['#EBECEB', '#2F4077'])
      const colors = {}
      for (const countryId in this.dataCountry) {
        const visits = this.dataCountry[countryId]
        colors[countryId] = visits > 0 ? colorScale(visits) : 'rgb(237,237,237)' //grey-1000
      }
      return colors
    },
    countryColorsHover() {
      const colorScale = d3.scaleLog()
        .domain([1, Math.max(...Object.values(this.dataCountry))])
        .range(['#E6EEE6', '#4e68bb'])
      const colors = {}
      for (const countryId in this.dataCountry) {
        const visits = this.dataCountry[countryId]
        colors[countryId] = (visits && visits !== 0) ? colorScale(visits) : 'rgb(246,246,246)'
      }
      return colors
    }
  },
  created() {
    this.chartId = 'myChart' + Math.floor(Math.random() * (1000))
    this.widgetId = 'widget' + Math.floor(Math.random() * (1000))
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/components/charts/mapWorldChart.scss';
</style>
