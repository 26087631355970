<template>

  <header role="banner" class="fr-header">
    <div class="fr-header__body">
      <div class="fr-container--fluid">
        <div class="fr-header__body-row">
          <div class="fr-header__brand">
            <div class="fr-header__brand-top fr-px-2w">
              <div class="fr-header__logo">
                <a href="https://www.info.gouv.fr/" class="fr-logo" title="Lien vers le site gouvernement.fr">
                  Gouvernement
                </a>
              </div>
              <div class="fr-header__navbar" id="modal-491" aria-labelledby="button-492">
                <button class="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="modal-491"
                  aria-haspopup="menu" aria-expanded="false" id="button-492" title="Menu">
                  Menu
                </button>
              </div>
            </div>
            <div class="fr-header__service">
              <div class="fr-header__service-content fr-px-2w">
                <router-link id="fr-header-link-accueil" :to="{ name: 'accueil' }"
                  title=" L’Observatoire des données des sites Internet de l’État - Accueil">
                  <p class="fr-header__service-title">
                    Observatoire des sites Internet de l’État
                  </p>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fr-header__menu fr-modal" id="modal-491" aria-labelledby="button-492">
      <div class="fr-container fr-ml-1w">
        <button class="fr-btn--close fr-btn" aria-controls="modal-491" title="Fermer">
          Fermer
        </button>
        <div class="fr-header__menu-links"></div>
        <app-navigation></app-navigation>
      </div>
    </div>
  </header>
</template>

<script>
import navigationDsfr from "./Navigation.vue";

export default {
  name: "headerDsfr",
  components: {
    "app-navigation": navigationDsfr,
  },
};
</script>

<style scoped lang="scss">
@import "../styles/components/header.scss";
</style>
