<template>
  <div class="fr-p-3w">
    <div class="fr-grid-row">
      <div class="fr-col-12 fr-col-md-3">
        <p class="fr-text--xs fr-text-mention--grey fr-mb-3v">Mise à jour : {{ serieObj.update_date }}</p>
        <p class="fr-text--sm fr-text--bold fr-mb-3v">{{ serieObj.total_study }}</p>
        <p class="fr-text--sm fr-text--bold fr-mb-3v">{{ serieObj.mean_study }}</p>
        <p class="fr-badge"
          v-bind:class="{ 'fr-badge--down': isDown, 'fr-badge--error': isRed, 'fr-badge--success': isGreen, 'fr-badge--info': isBlue }">
          {{ serieObj.evol_percentage }}</p>
      </div>
      <div class="fr-col-11 fr-col-lg">
        <line-chart :x=JSON.stringify(serieObj.serie_values.x) :y=JSON.stringify(serieObj.serie_values.y)
          :name=JSON.stringify(serieObj.legend)>
        </line-chart>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../componentsDsfr/LineChart.vue'

export default {
  name: 'LineSeriesChart',
  components: {
    LineChart
  },
  data() {
    return {
      isDown: false,
      isGreen: true,
      isRed: false,
      isBlue: false,
    }
  },
  props: {
    serieObj: Object
  },
  methods: {
    setEvolStyle() {
      this.isGreen = true
      this.isDown = false
      this.isRed = false
      if (this.serieObj.type_evol === 'baisse') {
        this.isDown = true
        this.isRed = true
        this.isGreen = false
      }
    }
  },
  mounted() {
    this.setEvolStyle()
  },
  watch: {
    serieObj: function () {
      this.setEvolStyle()
    }
  },
}
</script>
<style scoped lang="scss">
@import '../../styles/components/charts/lineSeriesChart.scss';
</style>
