<template>
      <div class="tooltip">
    <span class="tooltip-icon" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">?</span>
    <div v-if="showTooltip" class="tooltip-content">
      <table>
        <tr v-for="(line, index) in 5" :key="index">
          <td>Ligne {{ index + 1 }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
    name:'TooltipComponent',
  data() {
    return {
      showTooltip: false
    };
  }
};
</script>

<style scoped lang="scss">
@import "../styles/components/tooltipComponent.scss";
</style>